.loader_wrapp {
  position: absolute;
  background-color: #fff;
  backdrop-filter: blur(5px);
  width: 100%;
  height: 100vh;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
